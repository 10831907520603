import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import Api from './modules/Api';
import Utils from './modules/Utils';
import StyleUtils from './modules/StyleUtils';
import Constant from './modules/Constant';

import Div from './components/Div';

//const DashboardSoin = lazy(() => import('./components/DashboardSoin'));
//const AfterpartyHome = lazy(() => import('./components/AfterpartyHome'));
import AfterpartyStaticHome from './components/AfterpartyStaticHome';
import AfterpartyHome from './components/AfterpartyHome';
import DashboardSoin from './components/DashboardSoin';

import PageNotFound from './components/PageNotFound';

export class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                collections: [],
                items: [],
                following: [],
                ul: 0,
                id: -1,
            },
            userLoaded: false,
            includes: {},

            screen: this.getScreenObject(),
        }
    }

    componentDidMount() {
        Api.apiLoadProfile(this.setUser.bind(this));
        this.checkGlobalCookies();

        this.updateWindowDimensions();
        if (window && window.addEventListener) {
            window.addEventListener('resize', this.updateWindowDimensions.bind(this));
            // window.addEventListener('resize', () => {
            //     console.log("Utils.cssCalc(0) >>>>", Utils.cssCalc(0));
            //     alert(Utils.cssCalc(0));
            //     $("html").css("height", Utils.cssCalc(0) + "px");
            // });
            window.addEventListener("orientationchange", this.updateWindowDimensions.bind(this))
        }
    }

    componentWillUnmount() {
        if (window && window.removeEventListener) {
            window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
            window.removeEventListener('orientationchange', this.updateWindowDimensions.bind(this));
        }
    }

    getScreenObject() {
        const widthType = StyleUtils.getWidthType(window.innerWidth);
        const screen = {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            widthType: widthType,
            mobile: document.documentElement.clientWidth <= 812,
            landscape: document.documentElement.clientHeight < document.documentElement.clientWidth,
            // mobileLandscape: window.innerHeight < window.innerWidth && document.documentElement.clientWidth <= 812,
            mobileLandscape: window.orientation == 90 || window.orientation == -90,
        }

        return screen;
    }

    updateWindowDimensions() {
        if (window) {
            const screen = this.getScreenObject();

            this.setState({ screen: screen });
        }
    }

    setUser(responseOrUser, callback) {
        let user = {};

        if(Utils.get(responseOrUser, "data.user", false)) {
            user = Utils.get(responseOrUser, "data.user", null);
        } else if(Utils.get(responseOrUser, "email", false)) {
            user = responseOrUser;
        } else if(Utils.get(responseOrUser, "data.email", false)) {
            user = Utils.get(responseOrUser, "data", false);
        }
        let tempState = {};
        if(Utils.get(responseOrUser, "includes", false)) {
            tempState['includes'] = Utils.get(responseOrUser, "includes", false);
        }

        if(!user) {
            tempState['showLogin'] = true;
        } else {
            tempState['user'] = user;
        };

        user.smsVerificationEnabled = Utils.get(responseOrUser, "includes.smsVerificationEnabled", 0);

        tempState['userLoaded'] = true;
        try {
            window.dataLayer.push({
                'event': 'profileLoad',
                'pageload.user_id': Utils.get(user, 'id'),
                'pageload.user_email': Utils.get(user, 'email'),
                'pageload.user_phone': Utils.get(user, 'primary_phone1'),
            });
        } catch(err) {

        }

        this.setState(tempState, () => {
            if(callback && typeof callback === "function") {
                callback(tempState);
            }
        });
    }

    checkGlobalCookies() {
        const requestToCookie = {
            calendar: { cookie: "calender_override" },
            referrer: { cookie: "referrer" },
            affiliate: { cookie: "affiliate" },
            utm: { cookie: "utm_soindrop" },
        };
        for (var idx in requestToCookie) {
            var cookieVal = Utils.getRequest(idx);
            if (cookieVal) {
                var item = requestToCookie[idx];
                if (cookieVal == 0) {
                    Utils.deleteCookie(item["cookie"]);
                } else {
                    Utils.setCookie(item["cookie"], cookieVal);
                }
            }
        }

        if (Utils.getRequest('demo')) {
            Utils.setCookie('demomode', parseInt(Utils.getRequest('demo')));
        }
        if (Utils.getRequest('refer')) {
            Utils.setCookie('refer', Utils.getRequest('refer'));
        }
    }

    render() {
        const st = this.state;

        let globalState = {
            user: st.user,
            userLoaded: st.userLoaded,
            includes: st.includes,

            screen: st.screen,

            setUser: this.setUser.bind(this),

            //TODO implement this
            globals: {
                user: st.user,
                userLoaded: st.userLoaded,
                setUser: this.setUser.bind(this),
            }
        }
        const cgp = ( window.document.location.host.indexOf("conversations.xyz") != -1 || window.document.location.host.indexOf("readyai.ai") != -1 );
        const homePostId = cgp ? 275 : 83;
        if(cgp) {
            window.document.title = "ReadyAI";
        }

        return (
            <Router basename="/webapp">
                { /*<Suspense fallback={<div></div>}>*/}
                <Switch>

                    <Route exact path="/" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={homePostId} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/tos" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={63} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/dmca" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={64} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/privacypolicy" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={65} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post/" postId={homePostId} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/home" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={homePostId} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/utopian" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="utopian" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/utopian" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" postId={homePostId} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/festival" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="festival" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/platform" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="platform" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/festivalpasses/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="festivalpasses" />} />
                    <Route exact path="/festivaltickets/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="festivaltickets" />} />
                    <Route exact path="/mintutopian" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="mintutopian" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/guardian" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="guardian" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/marketplace" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="marketplace" />} />
                    <Route exact path="/marketplace2" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="marketplace2" />} />
                    <Route exact path="/post/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/p/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="post" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/collection/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="collection" />} />
                    <Route exact path="/testdev" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="testdev" />} />
                    <Route exact path="/readyaiprocessmanager" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="testdev" />} />

                    <Redirect from='/event/b' to="/event/bored_ape_experiences-727444920" />

                    <Route exact path="/events" render={(props) => <AfterpartyHome {...globalState} {...props} route="afterparty" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />
                    <Route exact path="/dashboard" render={(props) => <DashboardSoin {...globalState} {...props} route="dashboard" />} />

                    <Route exact path="/login" render={(props) => <AfterpartyHome {...globalState} {...props} route="login" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />} />
                    <Route exact path="/register" render={(props) => <AfterpartyHome {...globalState} {...props} route="register" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />} />
                    <Route exact path="/register2" render={(props) => <AfterpartyHome {...globalState} {...props} route="register2" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/verification/email" render={(props) => <AfterpartyHome {...globalState} {...props} route="verificationemail" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />} />
                    <Route exact path="/verification/phone" render={(props) => <AfterpartyHome {...globalState} {...props} route="verificationphone" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />} />
                    <Route exact path="/forgot" render={(props) => <AfterpartyHome {...globalState} {...props} route="forgot" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />}  />
                    <Route exact path="/changepassword" render={(props) => <AfterpartyHome {...globalState} {...props} route="changepassword" headerType={Constant.HEADER_TYPE_NINE_DOT} footer />}  />
                    {/* <Route exact path="/emailverify" render={(props) => <AfterpartyHome {...globalState} {...props} route="emailverify" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} /> */}
                    <Route exact path="/tickets/*" render={(props) => <AfterpartyHome {...globalState} {...props} route="guardianticketflow" headerType={Constant.HEADER_TYPE_NO_HEADER} />} />

                    <Route exact path="/museum" render={(props) => <AfterpartyHome {...globalState} {...props} route="museum" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />

                    <Route exact path="/nft/*" render={(props) => <DashboardSoin {...globalState} {...props} route="nftlisting" portalUrl={true} />} />
                    {/* TODO: deprecated once marketplace is launched, delete */}
                    <Route exact path="/soin/*" render={(props) => <DashboardSoin {...globalState} {...props} route="soinlisting" portalUrl={true} />} />
                    <Route exact path="/soin" render={(props) => <DashboardSoin {...globalState} {...props} route="soin" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />

                    <Route exact path="/search" render={(props) => <DashboardSoin {...globalState} {...props} route="search" />} />

                    <Route exact path="/creator" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="creator" />} />
                    <Route exact path="/creator/*/newnft" render={(props) => <AfterpartyHome {...globalState} {...props} route="newnft" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />
                    <Route exact path="/creator/*/newnft/*" render={(props) => <AfterpartyHome {...globalState} {...props} route="newnft" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />

                    <Route exact path="/discordverify" render={(props) => <DashboardSoin {...globalState} {...props} route="discordverify" />} />
                    <Route exact path="/event/*" render={(props) => <AfterpartyHome {...globalState} {...props} route="event" />} />
                    <Route exact path="/room/*" render={(props) => <AfterpartyHome {...globalState} {...props} route="room" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />

                    {/* <Route exact path="/privacypolicy" render={(props) => <AfterpartyHome {...globalState} {...props} route="privacypolicy" headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/tos" render={(props) => <AfterpartyHome {...globalState} {...props} route="post/" postId={63} headerType={Constant.HEADER_TYPE_NINE_DOT} />} />
                    <Route exact path="/dmca" render={(props) => <AfterpartyHome {...globalState} {...props} route="dmca" headerType={Constant.HEADER_TYPE_NINE_DOT} />} /> */}
                    <Route exact path="/404" render={(props) => <PageNotFound {...globalState} {...props} route="404" />} />
                    <Route exact path="/festival_accommodations" render={(props) => <AfterpartyHome {...globalState} {...props} route="festival_accommodations" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />

                    <Route exact path="/profile" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="profile" />} />
                    <Route exact path="/settings" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="settings" />} />
                    {/* NOTE: /public/aichat/artist_id-68 */}
                    {/* <Route exact path="/public/aichat/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="aichat" isPublic />} /> */}

                    {/* NOTE: /private/aichat/artist_id-68 */}
                    <Route exact path="/private/aichat" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="aichat" />} />
                    <Route exact path="/private/aichat/*" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="aichat" />} />
                    <Route exact path="/share/:shoutout_crc" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="share" headerType={Constant.HEADER_TYPE_NINE_DOT}/>} />
                    <Route exact path="/mywallet" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="mywallet" />} />
                    {/* <Route exact path="/p/*" render={(props) => <AfterpartyHome {...globalState} {...props} route="publicprofile" />} /> */}
                    <Route exact path="/blog" render={(props) => <AfterpartyHome {...globalState} {...props} route="blog" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />

                    <Route exact path="/nft" render={(props) => <AfterpartyHome {...globalState} {...props} route="nft" />} />
                    <Route exact path="/pwa" render={(props) => <AfterpartyStaticHome {...globalState} {...props} route="pwa" />} />

                    <Route exact path="/afterparty" render={(props) => <AfterpartyHome {...globalState} {...props} route="afterparty" headerType={Constant.HEADER_TYPE_SOIN_FLYOUT} />} />
                    <Redirect to="/home" />
                </Switch>
                { /* </Suspense> */}
            </Router>
        );
    }
}
